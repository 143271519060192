<!--
View for the public page with user login
-->
<template>
  <div class="h-100">
    <div v-if="paramFromRouter=='/not-verificated'" class="d-flex flex-column align-items-center h-100">
        <p class="text-left">
            After registration, you got an email in order to confirm your email address.<br/>
            If you did not recieve this email, you can get a new one with the button above.<br/>
            Please click on the link within this email to confirm your user registration.<br/>
            Afterward you can login and use the Kentenich database.
        </p>
        <div v-if="usernameOrEmail" class="container w-50">
            <input type="button" v-on:click="sendVerificationEmail" class="btn btn-secondary" :value="'Send Verification Email to\n'+usernameOrEmail">
        </div>
    </div>
    <div v-if="paramFromRouter=='/after-logout'" class="d-flex justify-content-center align-items-center h-100">
        Thank you for using the Kentenich database
    </div>
    <div v-if="(paramFromRouter!='/not-verificated')&&(paramFromRouter!='/after-logout')">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3 class="text-center">Login</h3>
                    <label for="login-email">Username or Email:</label><br>
                    <input @keyup="onUserEnter" v-bind:class="{ loginIncorrect: isLoginIncorrect }" v-model="usernameOrEmail" type="text" id="login-email" class="form-control">
                    <label for="login-password">Password:</label><br>
                    <input ref="passwordInput" @keyup="onPasswortEnter" v-bind:class="{ loginIncorrect: isLoginIncorrect }" v-model="password" type="password" id="login-password" class="form-control">
                    <span>Remember me</span> <span><input v-model="useLocalStorage" type="checkbox"></span><br/>
                    <input type="button" v-on:click="tryLogin" class="btn btn-secondary" value="Login">
                    <div class="text-right">
                        <a class="nav-link" v-on:click="register" href="#">Registrieren</a>
                    </div>
                    <div class="text-right">
                        <a class="nav-link" v-on:click="forgotPassword" href="#">Passwort vergessen</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<style>
  input.loginIncorrect {
    background-color: #ffcccc;
  }
  div.login-verification-text {
    border: 1px solid #ff7777;
    margin: 5px;
    padding: 5px;
    text-align: left;
  }
</style>
<script>
import SrvAuthentification from '../global/SrvAuthentification';
import SrvMessages from '../global/SrvMessages';

export default {
    data: function() {
        return {
            usernameOrEmail: '',
            password: '',
            isLoginIncorrect: false,
            useLocalStorage: false // flag for "remember me"
        };
    },
    // Depending on the previous page, the router gives a parameter.
    // paramFromRouter  is  the part of the route after "/login"
    // Valid values for route:
    // /login                   normal use
    // /login/my/original/path  originaly the user entered the protected router
    //                          /my/original/path
    //                          but we had to redirect to login page.
    //                          Then we find here the final route, to redirect after login.
    // /login/not-verificated   Show the text for not verificated users.
    // /login/after-logout      Show the text after a logout
    computed: {
        paramFromRouter: function() {
            const routeParts = this.$route.fullPath.split('/'); 
            if (!routeParts.shift()) // "" for /login...   "login" for login....
                routeParts.shift();  // [] or [my,original,pat]
            return routeParts.length ? '/' + routeParts.join('/') : '';
        }
    },
    methods: {
        tryLogin: function () {
            const that = this;
            SrvAuthentification.login(this.usernameOrEmail, this.password, this.useLocalStorage)
            .then(function(bearerToken) {
                if (bearerToken) {
                    const newRoute = that.paramFromRouter || 'search';
                    that.$router.push(newRoute).catch(function(){});
                }
                else
                    that.isLoginIncorrect = true;
            })
            .catch(function(error) {
                if (error && (typeof(error)=='object') && error.notVerificated)
                    that.$router.push('/login/not-verificated').catch(function(){});
                else
                    that.isLoginIncorrect = true;
            });
        },
        sendVerificationEmail: async function() {
            let result = null;
            try {
                // login once more with flag "send verification email"
                await SrvAuthentification.login(this.usernameOrEmail, this.password, false, true);
            } catch(ex) {
                if (ex && (typeof(ex)=='object') && ex.notVerificated)
                    SrvMessages.warn('Please check you emails and click to the verification link there.');
                else
                    SrvMessages.error(ex);
            }            
        },
        // the bearerToken may be stored within sessionStorage or localStorage (i.e. after F5 or after setting "remember me")
        // then, a new login is not necessary, but we must reload the userdata, which are lost after F5...
        register: function () {
            this.$router.push('/register').catch(function(){});
        },
        forgotPassword: function () {
            this.$router.push('/forgotPassword').catch(function(){});
        },
        onUserEnter: function(evt) {
            if (evt.key=='Enter')
                this.$refs.passwordInput.focus();
        },
        onPasswortEnter: function(evt) {
            if (evt.key=='Enter')
                this.tryLogin();
        }
    }
}               
</script>

