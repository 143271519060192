<!--
Display many comments
-->
<template>
  <div class="comments">
    <h1 v-if="comments.length">Kommentare</h1>
    <div v-for="comment in comments" class="commentsCommentFrame">
        <div>
            <span class="commentsTitle" v-text="comment.title"></span>
            <span class="commentsIds">Event-Id: {{comment.eventId}} Comment-Id: {{comment.commentId}}</span>
        </div>
        <div class="commentsText" v-text="comment.text"></div>
    </div>    
  </div>    
</template>
<style>
    div.comments {
        text-align:left;
    }
    div.commentsCommentFrame {
        margin-bottom: 30px;
    }
    span.commentsTitle {
        font-weight:700;
        font-size: 1.2em;
    }
    span.commentsIds {
        margin-left: 20px;
        font-size: 0.7em;
    }
    div.commentsText {
        white-space: pre-wrap;
    }
</style>
<script>
import SrvServerAccess from '../global/SrvServerAccess';
//import SrvFormatHelper from '../global/SrvFormatHelper';
//import CmpCtrlEvtTranslation from './CmpCtrlEvtTranslation.vue'
//import CtrlTree from '@/controls/CtrlTree.vue'
//import SrvMessages from '../global/SrvMessages';

export default {
    props: [
        'event-id'    // ID of the events, the comments belong to
    ],
    // emit  open-version(eventId, versionId)
    // emit  open-text(mediumId)
    data: function() {
        return  {
            comments: [] // [{comment_id:..., comment_text..., keywords:[{id:..., key_keyword: 'MyKeyword'}], ...}]        };
        };
    },
    computed: {
    },
    created: function() {
        this.loadData();
    },
    watch: {
        eventId: function(newEventId) {
            this.loadData();
        }
    },
    methods: {
        loadData: function() {
            this.comments = [];
            if (!this.eventId)
                return;
            const that = this;
            SrvServerAccess.request('POST', '/api/eventGetComments', {event_id: this.eventId}).then(
                function(serverData) {
                    // Format of serverData:
                    // {data:[{comment_id:..., comment_text..., keywords:[{id:..., key_keyword: 'MyKeyword'}], ...}]}
                    that.comments = [];
                    let i = 1;
                    serverData.data.comments.forEach(function(commentRecord) {
                        that.comments.push({
                            text: commentRecord.comment_text,
                            title: 'Kommentar '+i++,
                            commentId: commentRecord.pivot.comment_id,
                            eventId: commentRecord.pivot.event_id
                        });
                    });

                }, function(error) {
console.error(error);
                }
            );
        },
    },
    components: {
//        CmpCtrlEvtTranslation: CmpCtrlEvtTranslation,
//        CtrlTree: CtrlTree
    }
}

</script>
