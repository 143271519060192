<!--
Display an help text within an iframe
-->
<template>
  <div class="help-screen">
    <iframe id="help-screen-iframe" :src="helpUrl" frameborder="0"></iframe>
  </div>    
</template>
<style>
    div.help-screen {
        width: 100%;
        height: 95%;
    }
    iframe#help-screen-iframe {
        width: 100%;
height: 100%;
    }
</style>
<script>
export default {
    props: [
        'help-url'
    ],
    data: function() {
        return  {
        };
    },
    computed: {
    },
    created: function() {
    },
    methods: {
    },
    components: {
    }
}
</script>
