<!--
this is the header bar with the menu
bootstrap will manage, that on a pc we see a menu bar
and on a mobile we see a symbol to switch on a little menu
-->

<template>
  <b-navbar toggleable="lg" type="light" variant="light" fixed="top" class="headerNav">
    <!-- Left side logo -->
    <a class="navbar-brand" href="#">kentepedia</a>

    <!-- hamburger button for mobile view -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <!-- this area allways is visible on a large screen and collapsed on a mobile -->
    <b-collapse is-nav id="nav-collapse">
      <!-- area with menu items -->
      <b-navbar-nav>
        <b-nav-item v-if="loggedIn" :active="activeView==='search'" to="/search">Suche</b-nav-item>
        <b-nav-item v-if="loggedIn" :active="activeView==='settings'" to="/settings">Einstellungen</b-nav-item>
<!--
        <b-nav-item v-if="adminMode" :active="activeView==='user'" class="nav navbar-nav nav-item" to="/user" >Benutzerverwaltung</b-nav-item>
-->
        <b-nav-item v-if="loggedIn" target="help" href="https://docs.google.com/document/d/1z9MgBwRGAlTdDKENKr36xhNeJTL8y5od_of2GuYDLjA/edit#heading=h.vsjzvpb5b840">Hilfe</b-nav-item>
        <b-nav-item :active="activeView==='about'" to="/about">Über</b-nav-item>
      </b-navbar-nav>

      <!-- only for debug and developement purpose -->
      <b-navbar-nav class="ml-auto">
        <cmp-login-info />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<style>
</style>
<script>
import CmpLoginInfo from './CmpLoginInfo.vue';
import SrvAuthentification from '../../global/SrvAuthentification';
export default {
    data: function() {
        return {
            adminMode: false,
            loggedIn: false
        };
    },
    computed: {
        activeView: function () {
            return this.$route.name;
        }
    },
    created: function() {
        const that = this;
        SrvAuthentification.addListener(function(userData) {
            that.authentificationListener(userData);
        });
    },
    destroyed: function() {
        SrvAuthentification.removeListener(this.authentificationListener);
    },
    methods: {
        authentificationListener: function(data) {
            this.adminMode = false;
            if (data.userData && data.userData.permissions) {
                if (  (data.userData.permissions.indexOf('jk_admin')>=0)
                    ||(data.userData.permissions.indexOf('jk_admin')>=0))
                    this.adminMode = true;
            }
            this.loggedIn  = data.sessionToken ? true : false;
            // only for the vuex store mock for Daniels admin views.
            this.$store.state.sessionToken = data.sessionToken;
        }
    },
    components: {
        cmpLoginInfo: CmpLoginInfo
    }
}
</script>

    