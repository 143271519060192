<!--
this is the main component

the idea is:
there is a frame with an upper menu bar to be visible allways.
below there is place for different views. The router will decide, which view
actualy is visible and will place it within <router-view>

to continue reading the code, look to Router.routes,
which component is displayed within <routerview>
for the current hash part of the url.
-->

<template>
    <div id="app" class="h-100">
        <cmp-header/>
        <div id="app-mainFrame" class="h-100">
            <cmp-message/>
            <router-view id="routerView" />
        </div>
    </div>
</template>

<style>
html,body {
    height:100%
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

#app-mainFrame {
    padding-top:60px;
}

#routerView {
}
</style>
<script>
// @ is an alias to /src
import CmpHeader from '@/views/header/CmpHeader.vue'
import CmpMessage from '@/views/header/CmpMessage.vue'
export default {
    components: {
        cmpHeader: CmpHeader,
        cmpMessage: CmpMessage
    },
    mounted: function() {
        document.title = "kentepedia";  
    }
}
</script>

